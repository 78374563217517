<template>
  <section class="static">
    <div class="container">
      <h1>Kebijakan Privasi</h1>
      <h4 class="mt40">Pendahuluan</h4>
      <p class="mb30 text-justify">
        Kebijakan Privasi ini menjelaskan bagaimana informasi pribadi Anda
        dikumpulkan, digunakan, dan dibagikan ketika Anda mengunjungi atau
        melakukan pembelian dari https://surfaceskinhabit.com.<br />
        Informasi pribadi yang kami kumpulkan saat Anda mengunjungi Situs, kami
        secara otomatis mengumpulkan informasi tertentu tentang perangkat Anda,
        termasuk informasi tentang browser web Anda, alamat IP, zona waktu, dan
        beberapa cookie yang diinstal pada perangkat Anda. Selain itu, saat Anda
        menjelajahi Situs, kami mengumpulkan informasi tentang halaman web
        individu atau produk yang Anda lihat, situs web atau istilah pencarian
        apa yang mengarahkan Anda ke Situs, dan informasi tentang bagaimana Anda
        berinteraksi dengan Situs. Kami menyebut informasi yang dikumpulkan
        secara otomatis ini sebagai "Informasi Perangkat". <br />
        Berbagi Informasi Pribadi Anda: <br />
        Kami membagikan Informasi Pribadi Anda dengan pihak ketiga untuk
        membantu kami menggunakan Informasi Pribadi Anda, seperti yang
        dijelaskan di atas. Terakhir, kami juga dapat membagikan Informasi
        Pribadi Anda untuk mematuhi hukum dan peraturan yang berlaku, untuk
        menanggapi panggilan pengadilan, surat perintah penggeledahan atau
        permintaan sah lainnya untuk informasi yang kami terima, atau untuk
        melindungi hak kami. <br />
        Jangan lacak <br />
        Harap perhatikan bahwa kami tidak mengubah pengumpulan data dan praktik
        penggunaan Situs kami saat kami melihat sinyal Jangan Lacak dari browser
        Anda. <br />
        Retensi data <br />
        Ketika Anda melakukan pemesanan melalui Situs, kami akan menyimpan
        Informasi Pesanan Anda untuk catatan kami kecuali dan sampai Anda
        meminta kami untuk menghapus informasi ini. <br />
        Perubahan <br />
        Kami dapat memperbarui kebijakan privasi ini dari waktu ke waktu untuk
        mencerminkan, misalnya, perubahan pada praktik kami atau untuk alasan
        operasional, hukum atau peraturan lainnya. <br />
        Anak di bawah umur <br />
        Situs ini tidak ditujukan untuk individu di bawah usia 18 tahun atau
        individu yang belum dianggap dewasa oleh peraturan yang berlaku
        berdasarkan Undang Undang Negara Republik Indonesia. <br />
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: "Privacy Policy"
  }
};
</script>
